@import "../../App/App.scss";

.senior-lifestory {
	margin-top: -1rem;
}

// STORIES

.stories-list li {
	list-style: none;
}

// NAV-LINK

.responsive-nav-link {
	margin-top: 0.2rem;
	border: solid 3px lighten($color: $secondary-color, $amount: 2);
	border-radius: 8px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100%;

	.meta {
		font-family: $text-font;
		font-size: 0.8rem;
		color: $grey;
		font-weight: 300;
		letter-spacing: 0px;
		line-height: 1.5rem;
		word-break: break-word;
		white-space: pre-wrap;
		-moz-white-space: pre-wrap;
	}
}

.link-nav-button {
	flex: 1 20%;
	padding: 0.5rem 0;
	cursor: pointer;
	margin: 0;

	.icon-actions {
		width: auto;
		height: 1.4rem;
		color: $primary-color;
		vertical-align: middle;
	}
}

.link-nav-button:hover {
	background-color: lighten($color: $secondary-color, $amount: 2);
}

.link-nav-button-hover {
	flex: 1 20%;
	min-width: 7rem;
	padding: 0.5rem;
	margin: 0;
	background-color: lighten($color: $secondary-color, $amount: 2);
	border-radius: 7px;

	.icon-actions {
		width: auto;
		height: 1.4rem;
		color: $primary-color;
		vertical-align: middle;
	}
}

// MODAL

.overlay {
	z-index: 99;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	// align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	padding-top: 4.3rem;
}

.modal-rgpd {
	z-index: 1;
	position: relative;
	width: 100%;
	max-width: 650px;
	max-height: 550px;
	overflow-y: scroll;
}

.modal {
	z-index: 1;
	position: relative;
	width: 100%;
	max-width: 600px;
	max-height: 550px;
}

.modal-close {
	position: absolute;
	top: 0.75rem;
	right: 1rem;
	margin-bottom: 0.5rem;
	background: none;
	color: $primary-color;
	cursor: pointer;
	font-family: $text-font;
	font-weight: 600;
	font-size: 1.25rem;
}

.modal-body {
	margin: 0;
	padding: 1rem;
	border-radius: 10px;
	background-color: $light-color;
}

// BIO
.stories-list {
	margin-bottom: 1.5rem;
}

.stories-list li {
	border-bottom: solid 1px $light-grey;
	padding: 0.75rem 0.25rem;
}

.img-container {
	width: 640px;
	height: 480px;
	float: left;
}

.story-card {
	border: solid 2px $secondary-color;
	border-radius: 12px;
	margin-top: 1rem;
	padding: 1rem;
	transition-duration: 0.4s;
}

.story:hover {
	text-decoration: line-through;
	cursor: pointer;
}
.suppr {
	color: $contrast-color;
	height: 1.25rem;
	width: auto;
}
.suppr:hover {
	color: $hover-color;
	cursor: pointer;
}

.senior-home p {
	margin: 0.5rem 0;
	p {
		margin: 0;
	}
	h3 {
		margin: 0;
	}
}

// SENIOR HOME

.avatar-container {
	display: flex;
	justify-content: center;
	flex-direction: rows;
	position: relative;

	.avatar-action {
		position: absolute;
		width: 38px;
		height: 38px;
		background-color: $secondary-color;
		padding-top: 6px;
		border-radius: 50%;
		top: 62%;
		left: 58%;
		cursor: pointer;
	}
	.icon-camera {
		margin: 0.35rem 0 0 0;
		padding: 0;
		width: 41px;
		color: $primary-color;
	}
}

.avatar {
	width: 120px;
	height: 120px;
	margin: 0.75rem;
	border-radius: 50%;
	border: solid 3px $secondary-color;
	position: relative;
}

.avatar-component {
	position: relative;
}

.avatar-button {
	height: 2.7rem;
	width: auto;
	background-color: $secondary-color;
	color: $primary-color;
	position: absolute;
	top: 55%;
	left: 60%;
	padding: 0.5rem;
	border-radius: 50%;
	cursor: pointer;
}

.avatar-button:hover {
	background-color: $hover-color;
	color: $white;
}

.senior-actions {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	padding: 0.5rem;
	cursor: pointer;
	margin: 0;

	.icon-actions {
		margin: 0 0 0.5rem 0;
		width: auto;
		height: 20px;
	}
}

.senior-actions-hover {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	padding: 0.5rem;
	cursor: pointer;
	margin: 0;
	background-color: lighten($color: $secondary-color, $amount: 2);
	border-radius: 7px;

	.icon-actions {
		margin: 0 0 0.5rem 0;
		width: auto;
		height: 20px;
	}
}

.no-margin {
	margin: 0;
	padding: 0;
}

.senior-actions:hover {
	background-color: lighten($color: $secondary-color, $amount: 2);
	border-radius: 7px;
}

.light-background {
	background-color: lighten($color: $secondary-color, $amount: 3);
	margin: 1rem 0;
	min-height: 28rem;
	padding-bottom: 1rem;
}

#preview-avatar {
	margin: 0 auto;
	display: block;
}

// useUploadPictures
.customInputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.customInputfile + label {
	@include fluid-type(0.9rem, 0.95rem);
	font-family: $text-font;
	color: $primary-color;
	display: inline-block;
	cursor: pointer;
	text-align: center;
	padding: 0 0.5rem;
	margin: 0;
	border: solid 1px $light-grey;
	border-radius: 7px;
}

.customInputfile:focus + label,
.customInputfile + label:hover {
	background-color: $primary-color;
	color: $white;
}

// Send Message : input file

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
	@include fluid-type(0.9rem, 0.95rem);
	font-family: $text-font;
	color: $white;
	background-color: $primary-color;
	border-radius: 7px;
	display: inline-block;
	cursor: pointer;
	text-align: center;
	padding: 0.5rem 0.75rem;
	margin: 0.75rem 0 0.25rem 0;
	font-weight: 600;
}

.inputfile:focus + label,
.inputfile + label:hover {
	background-color: $hover-color;
}

// Messages sent

.colored-background {
	background-color: lighten($color: $secondary-color, $amount: 2);
	margin: 0.5rem 0;
	padding: 0.75rem;
	position: relative;
	border-radius: 8px;
}

.thumbnail-img {
	margin: 0.5rem;
	height: 6.5rem;
	width: auto;
	max-width: 8rem;
	object-fit: contain;
}

.rgpd {
	margin: 1rem 0 0.5rem 0;
}

.underliner {
	border-bottom: solid 1px $primary-color;
	width: 20%;
	margin-bottom: 1rem;
}

.large-visio-button {
	background: lighten($color: $secondary-color, $amount: 2);
	color: $primary-color;
	font-family: $text-font;
	font-size: 1rem;
	font-weight: 400;
	border-radius: 6px;
	text-decoration: none;
	padding: 0.5rem;
	margin: 1rem 0;
	transition-duration: 0.4s;
	border: none;
	cursor: pointer;
}

.link-message-icon {
	width: auto;
	height: 1.5rem;
	vertical-align: middle;
}

.large-visio-button:hover {
	background-color: $primary-color;
	color: $white;
}

.large-visio-button:focus {
	outline: 0;
}

.start-visio {
	vertical-align: middle;
	margin-right: 0.5rem;
	margin-top: -3px;
	width: 28px;
	height: auto;
}

.slideshow {
	width: 35rem;
	height: 25rem;
}
.slideshow-selected-pict {
	width: 100%;
	height: 100%;
	object-fit: contain;
	padding: 0;
	margin: 0;
}

.slideshow-background {
	background-color: lighten($color: $secondary-color, $amount: 2);
	margin: 0.5rem 0;
	padding: 0;
	max-height: 10rem;
	overflow: scroll;
	position: relative;
	border-radius: 8px;
}

.more-picts {
	display: inline-block;
	position: absolute;
	bottom: 1.8rem;
	color: $contrast-color;
	font-family: $title-font;
	font-size: 1.5rem;
	cursor: pointer;
	z-index: 99;
	margin: 0 0 0 0.5rem;
}

.colored-card {
	background-color: $secondary-color;
	border-radius: 7px;
	margin: 1rem 0;
	padding: 0.8rem;
	position: relative;
}

.senior-message {
	font-size: 1.1rem;
	letter-spacing: 0.25px;
}

.preview-post {
	position: relative;
	display: inline-block;
	margin: 0 0.5rem 0.5rem 0;
}

.preview-pict {
	width: 120px;
	height: 120px;
	object-fit: cover;
}

@media screen and (max-width: 800px) {
	.slideshow {
		width: auto;
		height: 55vw;
		max-height: 20rem;
		margin: 0 auto;
	}
}
