@import "../../App/App.scss";

// Media Nav

.submenu-container {
	width: 95%;
	margin-top: 1rem;
	display: block;
}

.submenu {
	border: solid 2px $secondary-color;
	color: $primary-color;
	font-family: $text-font;
	@include fluid-type(0.9rem, 0.95rem);
	cursor: pointer;
	width: 100%;
	margin-top: -2px;
	padding: 0.75rem;
	font-weight: 400;
}

.submenu:hover {
	background-color: lighten($color: $secondary-color, $amount: 2);
}

.submenu-focus {
	background-color: $secondary-color;
	border: solid 2px $secondary-color;
	color: $primary-color;
	font-family: $text-font;
	@include fluid-type(0.9rem, 0.95rem);
	cursor: pointer;
	width: 100%;
	margin-top: -2px;
	padding: 0.75rem;
}

// Notifications Box

.msgDetailsList {
	list-style: none;
	margin: 0;
	padding: 0;
}

.msgDetailsList li {
	margin: 0;
	padding: 0;
}

.notifications {
	list-style: none;
	margin-top: 0.5rem;
}

.space {
	margin: 2rem 0;
}

.new-message {
	color: $hover-color;
	font-size: 0.85rem;
}

.back {
	cursor: pointer;
	color: $grey;
	font-size: 0.8rem;
}

.notifications li {
	border-bottom: solid 2px $secondary-color;
	border-top: solid 2px $secondary-color;
	padding: 1.25rem 0.25rem;
	margin: -2px 0;
}

// .notifications li:hover {
//     background-color: lighten($color: $secondary-color, $amount: 1);
// }

.medianav-icon {
	vertical-align: middle;
	width: 24px;
	height: auto;
	margin-right: 1rem;
	margin-top: -0.2rem;
}

.media-header-notification {
	position: relative;
}

.media-notification {
	background-color: $contrast-color;
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	position: absolute;
	top: 21%;
	left: 17%;
	color: $white;
	font-size: 0.7rem;
	font-weight: 600;
	text-align: center;
}

.unread-container {
	padding: 1rem 0 1rem 0;
	margin: 0 0 1rem 0;
	background-color: lighten($color: $secondary-color, $amount: 4);
}

.read-container {
	border-bottom: solid 3px $secondary-color;
	width: 100%;
}

.subject {
	font-size: 1.05rem;
}

// MessageBox

.recipients-choice {
	list-style: none;
	margin: 1rem 0;
}

.recipient {
	font-size: 0.9rem;
	border: solid 1px $light-grey;
	padding: 0.5rem;
	margin-right: 0.5rem;
	color: $primary-color;
	border-radius: 7px;
	cursor: pointer;
}

.recipient:hover {
	background-color: $secondary-color;
	border: solid 1px $secondary-color;
}

.selected-recipient {
	font-size: 0.9rem;
	background-color: $primary-color;
	border: solid 1px $primary-color;
	padding: 0.5rem;
	margin-right: 0.5rem;
	color: $white;
	border-radius: 7px;
	cursor: pointer;
}

.answer-icon {
	vertical-align: middle;
	width: 21px;
	height: auto;
	margin-right: 5px;
}

.check-icon {
	vertical-align: middle;
	width: 16px;
	height: auto;
	margin-right: 5px;
}

.uncheck-icon {
	vertical-align: middle;
	width: 20px;
	height: auto;
}

.bullet-message {
	padding: 1rem 0;
}

.different-sender {
	color: $green;
	font-style: italic;
	@include fluid-type(0.85rem, 0.9rem);
}

/// Documents

.documents {
	list-style-type: none;
	margin: 1rem;
}

.document-header {
	border-bottom: solid 4px $secondary-color;
	padding: 0.5rem;
}

.document-body {
	border-bottom: solid 2px $secondary-color;
	padding: 0.8rem 0.2rem;
}

.download-doc-icon {
	width: 2rem;
	height: auto;
	cursor: pointer;
}

.doc-list,
.doc-list a {
	border-bottom: solid 2px $secondary-color;
	padding: 0.8rem 0;
	cursor: pointer;
}

.doc-list:hover {
	background-color: lighten($color: $secondary-color, $amount: 3);
}

// Notifications Details

.response-card {
	position: relative;
	border-radius: 7px;
	margin: 2rem 5rem 1rem 0;
	padding: 1rem;
	background: $secondary-color;
	transition-duration: 0.4s;
}

.response-card:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 5%;
	width: 0;
	height: 0;
	border: 15px solid transparent;
	border-top-color: $secondary-color;
	border-bottom: 0;
	border-right: 0;
	margin-left: -9px;
	margin-bottom: -15px;
}

.response-card-unread {
	position: relative;
	border: solid 2px $contrast-color;
	border-radius: 7px;
	margin: 2rem 5rem 1rem 0;
	padding: 1rem;
	background: lighten($color: $secondary-color, $amount: 3);
	transition-duration: 0.4s;
}
.response-card-unread:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 5%;
	width: 0;
	height: 0;
	border: 15px solid transparent;
	border-top-color: $contrast-color;
	border-bottom: 0;
	border-right: 0;
	margin-left: -9px;
	margin-bottom: -15px;
}

.family-card {
	position: relative;
	border-radius: 7px;
	margin: 2rem 0 2rem 5rem;
	padding: 1rem;
	background: lighten($color: $grey, $amount: 57);
}

.family-card:after {
	content: "";
	position: absolute;
	bottom: 0;
	right: 5%;
	width: 0;
	height: 0;
	border: 15px solid transparent;
	border-top-color: lighten($color: $grey, $amount: 55);
	border-bottom: 0;
	border-left: 0;
	margin-left: -9px;
	margin-bottom: -15px;
}

.family-card-unread {
	position: relative;
	border: solid 2px $grey;
	border-radius: 7px;
	margin: 2rem 0 2rem 5rem;
	padding: 1rem;
	background: lighten($color: $secondary-color, $amount: 3);
}

.family-card-unread:after {
	content: "";
	position: absolute;
	bottom: 0;
	right: 5%;
	width: 0;
	height: 0;
	border: 15px solid transparent;
	border-top-color: $grey;
	border-bottom: 0;
	border-left: 0;
	margin-left: -9px;
	margin-bottom: -15px;
}

.first-card {
	border: solid 1px $contrast-color;
	border-radius: 7px;
	margin: 2rem 0;
	padding: 1rem;
	background: $light-color;
}

.meta-date {
	font-family: $text-font;
	font-size: 0.75rem;
	color: $grey;
}

.response-icon {
	width: 21px;
	height: auto;
	vertical-align: middle;
	margin-right: 3px;
}

.visio-comment {
	font-family: $text-font;
	@include fluid-type(0.82rem, 0.85rem);
	font-weight: 300;
	letter-spacing: 0.2px;
	line-height: 1.2rem;
	&:unread {
		color: $contrast-color;
	}
	&:read {
		color: $grey;
	}
}

.notification-icons {
	display: flex;
	vertical-align: middle;
}

.type-notification {
	width: 25px;
	height: auto;
	margin-right: 0.85rem;
	color: $primary-color;
}
.type-notification:hover {
	color: $hover-color;
}

.visio-button {
	background: $light-color;
	color: $primary-color;
	font-family: $text-font;
	font-size: 0.75rem;
	border-radius: 8px;
	border: solid 1px $light-grey;
	text-align: center;
	text-decoration: none;
	padding: 0.15rem 0.5rem;
	margin: 0 0.5rem;
	transition-duration: 0.4s;
	cursor: pointer;
}

.visio-button:hover {
	background-color: $secondary-color;
	border: solid 1px $secondary-color;
}

.visio-button:focus {
	outline: 0;
}

.step-number {
	font-size: 1rem;
	font-weight: 600;
	color: $white;
	background-color: darken($color: $secondary-color, $amount: 4);
	width: 1.75rem;
	height: 1.75rem;
	border-radius: 50%;
	text-align: center;
}

.visio-rules {
	font-family: $text-font;
	color: $primary-color;
	li {
		margin: 0.35rem 0 0 1rem;
		font-weight: 400;
		color: $primary-color;
		font-family: $text-font;
	}
}

.text-link {
	cursor: pointer;
	color: $hover-color;
}

.text-link:hover {
	text-decoration: underline;
}

.read-button {
	background: $light-color;
	color: $primary-color;
	font-family: $text-font;
	font-size: 0.75rem;
	border-radius: 5px;
	text-decoration: none;
	padding: 0 0.5rem 0 0;
	margin: 0.25rem 0.25rem 0 0;
	transition-duration: 0.4s;
	border: solid 1px lighten($color: $grey, $amount: 35);
	cursor: pointer;
}

.read-button:hover {
	background-color: $secondary-color;
}

.read-button:focus {
	outline: 0;
}
