// COLORS
$primary-color: #4a4e64;
$secondary-color: #ffeddf;
$light-color: #fffefb;
$hover-color: #00a0e3;
$contrast-color: #fe7062;
$grey: #686868;
$light-grey: #b5b5b5;
$white: #fff;
$green: #50c878;

body {
	background-color: $light-color;
	min-height: 100%;
	text-rendering: optimizeLegibility;
	font-weight: 400;
	color: $primary-color;
	font-size: 0.95rem;
	overflow-x: hidden;
	position: relative;
	width: 100%;
	max-width: 100vw;
	margin: 0;
}

html {
	box-sizing: border-box;
	height: 100%;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

.content-container {
	padding: 1rem;
	margin: 0;
	overflow: hidden;
	height: 100%;
}

.no-scrollbar {
	overflow: hidden;
}

ul {
	margin: 0;
	padding: 0;
}

li {
	margin: 0 0 0 1rem;
	padding: 0;
}

.footer-content {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;

	.app-version {
		color: $grey;
		font-size: 0.72rem;
		letter-spacing: 0.5px;
	}
}

$title-font: "PoppinsBold", sans-serif;
$text-font: "Poppins", sans-serif;
$light-font: "PoppinsLight", sans-serif;

// RESPONSIVE FONT SIZE
@mixin fluid-type($font-min, $font-max) {
	font-size: #{$font-min};

	@media only screen and (min-width: 600px) {
		font-size: calc(#{$font-min} + #{($font-max - $font-min)} * (100vw - 600px) / (1200 - 600));
	}

	@media only screen and (min-width: 1200px) {
		font-size: #{$font-max};
	}
}

// TYPOGRAPHY

p {
	font-family: $text-font;
	@include fluid-type(0.9rem, 0.95rem);
	color: $primary-color;
	margin: 0;
	line-height: 1.6rem;
	font-weight: 400;
	white-space: pre-line;
	strong {
		font-weight: 600;
	}
}

a {
	font-family: $text-font;
	text-decoration: none;
	color: $hover-color;
}

a:visited {
	color: $hover-color;
}

a:hover {
	color: $primary-color;
}

label {
	margin: 0;
	padding: 0;
	font-family: $light-font;
	@include fluid-type(0.8rem, 0.83rem);
	color: $grey;
	letter-spacing: 0.2px;
}

.blue {
	color: $hover-color;
}

.red {
	color: $contrast-color;
}

.grey {
	color: lighten($color: $primary-color, $amount: 30);
}

.mt-2 {
	margin-top: 15px;
}

.mt-5 {
	margin-top: 25px;
}

.mb-5 {
	margin-bottom: 15px;
}

.tooltip-container {
	position: relative;
}

.tooltip-box {
	width: 100%;
	min-width: 4.5rem;
	@include fluid-type(0.85rem, 0.87rem);
	text-align: center;
	position: absolute;
	background: rgba(0, 0, 0, 0.7);
	color: #fff;
	padding: 2px;
	border-radius: 5px;
	top: 3.5rem;
	display: none;
	right: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.tooltip-box.visible {
	display: block;
}

.alert {
	font-family: $text-font;
	font-size: 1rem;
	color: darken($color: $contrast-color, $amount: 5);
	margin: 0.5rem;
	padding: 0.5rem;
	font-weight: 600;
	border-left: solid 12px $contrast-color;
	border-right: solid 1px $contrast-color;
	border-top: solid 1px $contrast-color;
	border-bottom: solid 1px $contrast-color;
	border-radius: 7px;
	animation-duration: 1s;
	animation-name: slidein;

	@keyframes slidein {
		from {
			margin-left: 100%;
			width: 300%;
		}

		to {
			margin-left: 0%;
			width: 100%;
		}
	}
}

.success {
	font-family: $text-font;
	font-size: 1rem;
	color: $primary-color;
	border-left: solid 12px $primary-color;
	border-right: solid 1px $primary-color;
	border-top: solid 1px $primary-color;
	border-bottom: solid 1px $primary-color;
	border-radius: 7px;
	padding: 1rem;
	margin: 1.5rem 0;
	animation-duration: 1s;
	animation-name: slidein;
	font-weight: 400;

	@keyframes slidein {
		from {
			margin-left: 100%;
			width: 300%;
		}

		to {
			margin-left: 0%;
			width: 100%;
		}
	}
}

.green-success {
	font-family: $text-font;
	@include fluid-type(0.85rem, 0.9rem);
	color: $primary-color;
	border-left: solid 12px $green;
	border-right: solid 1px $green;
	border-top: solid 1px $green;
	border-bottom: solid 1px $green;
	border-radius: 7px;
	padding: 0.5rem;
	margin: 1rem 0;
	animation-duration: 1s;
	animation-name: slidein;
	font-weight: 400;

	@keyframes slidein {
		from {
			margin-left: 100%;
			width: 300%;
		}

		to {
			margin-left: 0%;
			width: 100%;
		}
	}
}

h1 {
	font-family: $title-font;
	@include fluid-type(1.65rem, 1.75rem);
	color: $primary-color;
	letter-spacing: 1px;
	padding: 0.5rem 0;
}

h2 {
	font-family: $title-font;
	@include fluid-type(1.15rem, 1.2rem);
	color: $primary-color;
	// padding: 0.25rem 0;
	letter-spacing: 0.25px;
}

h3 {
	font-family: $title-font;
	@include fluid-type(1rem, 1.05rem);
	color: $primary-color;
	padding: 0.2rem 0;
	letter-spacing: 0.2px;
}

h4 {
	font-family: $title-font;
	@include fluid-type(0.95rem, 1rem);
	color: $primary-color;
	background-color: lighten($color: $secondary-color, $amount: 2);
	padding: 0.5rem;
	font-weight: 400;
	letter-spacing: 0.4px;
}

.pdf-file {
	margin: 0.5rem 0;
	font-family: $title-font;
	@include fluid-type(0.95rem, 1rem);
	color: $primary-color;
	background-color: lighten($color: $secondary-color, $amount: 2);
	padding: 0.5rem;
	letter-spacing: 0.2px;
}

.tips {
	font-family: $text-font;
	@include fluid-type(0.95rem, 1.05rem);
	color: $primary-color;
	background-color: lighten($color: $secondary-color, $amount: 2);
	padding: 1rem;
	margin: 1rem 0;
	border-radius: 10px;
}

.tips-icon {
	vertical-align: middle;
}

.alert-info {
	.alert-icon {
		vertical-align: middle;
		color: $contrast-color;
		height: 1.2rem;
		width: auto;
		margin: 0 0.3rem;
	}
}

.small-meta {
	font-family: $light-font;
	@include fluid-type(0.75rem, 0.78rem);
	color: $grey;
	letter-spacing: 0px;
	line-height: 1rem;
}

.meta {
	font-family: $light-font;
	@include fluid-type(0.77rem, 0.8rem);
	color: $grey;
	letter-spacing: 0.2px;
	line-height: 1.2rem;
	word-break: break-word;
	white-space: pre-wrap;
	-moz-white-space: pre-wrap;
}

.meta-red {
	font-family: $text-font;
	@include fluid-type(0.8rem, 0.83rem);
	color: $contrast-color;
	line-height: 1.2rem;
	margin-left: 0.4rem;
	word-break: break-word;
	white-space: pre-wrap;
	-moz-white-space: pre-wrap;
}

.big-meta {
	font-family: $text-font;
	@include fluid-type(0.85rem, 0.9rem);
	letter-spacing: 0.5px;
	line-height: 1.3rem;
	color: $grey;

	&.green {
		color: $green;
	}
}

.meta-underliner {
	border-bottom: solid 1px $light-grey;
	width: 25%;
	margin: 0.25rem 0 0 0;
}

.meta-checkbox {
	font-family: $text-font;
	font-size: 0.9rem;
	color: $grey;
	a {
		color: $hover-color;
	}
}

.link {
	color: $contrast-color;
	cursor: pointer;
}

.meta-input {
	font-family: $text-font;
	font-style: italic;
	@include fluid-type(0.7rem, 0.8rem);
	color: $grey;
}

.meta-link {
	font-family: $text-font;
	@include fluid-type(0.85rem, 0.9rem);
	color: $hover-color;
	letter-spacing: 0.25px;
	cursor: pointer;
	margin: 1.5rem 0 0.75rem 0;
}

.meta-link:hover {
	color: $grey;
}

.my-input {
	margin: 1rem 0 1.5rem 0;
}

.welcome-list {
	padding: 0 0 0 1.5rem;

	li {
		padding: 0.5rem;
	}
}

.error {
	color: $contrast-color;
	font-weight: 600;
}

.ml-5 {
	margin: 0 0 0 1.5rem;
}
.mr-2 {
	margin: 0 1.25rem 0 0;
}
.center {
	text-align: center;
}

.left {
	float: left;
}

.right {
	float: right;
}

.large {
	width: 100%;
}

.white {
	color: $white;
}

.card {
	border: solid 2px $secondary-color;
	border-radius: 7px;
	margin: 0.5rem;
	padding: 0.75rem;
	position: relative;
}

.flow-card {
	border: solid 2px $secondary-color;
	border-radius: 7px;
	margin: 0 0 0.4rem 0 !important;
	padding: 0.75rem;
	position: relative;
}

.flow-arrow {
	position: relative;
	width: 100%;
	min-width: 2rem;
	max-width: 2.5rem;
}

.read-more-icon {
	vertical-align: middle;
	width: auto;
	height: 1.3rem;
	display: inline;
	cursor: pointer;
	color: $hover-color;
	position: absolute;
	bottom: 0;
	right: 0;
}

.read-more-icon:hover {
	color: $primary-color;
}

.unread-card {
	border: solid 2px $secondary-color;
	background-color: lighten($color: $secondary-color, $amount: 3);
	border-radius: 7px;
	margin: 0 0 0.4rem 0 !important;
	padding: 0.75rem;
	position: relative;
}

.visio-card {
	border-radius: 7px;
	margin: 0.4rem 0;
	padding: 0.75rem;
	position: relative;
	color: $primary-color;

	&.blue {
		border: solid 2px $hover-color;
	}
	&.red {
		border: solid 2px $contrast-color;
		background-color: lighten($color: $secondary-color, $amount: 3);
	}
}

.light-card {
	padding: 1em;
	margin: 0;
}

.separator {
	margin: 0 auto;
	padding: 1rem 0;
	width: 50%;
	border-top: solid 2px $secondary-color;
}

.white-separator {
	margin: 1rem 0;
	padding: 0;
	width: 50%;
	border-top: solid 2px white;
}

.divider {
	margin: 2rem 0;
	width: 20rem;
	border-bottom: solid 2px $secondary-color;
}

.inline {
	display: inline;
}

.more-info-icon {
	cursor: pointer;
	width: auto;
	height: 1.2rem;
	color: $hover-color;
	margin: 0.5rem 0.5rem 0 0;
}
.more-info-icon:hover {
	color: $contrast-color;
}

// AFFILIATE ICONS

.btn-icon {
	border: none;
	cursor: pointer;
	width: auto;
	height: 1.2rem;
	color: $contrast-color;
}

.btn-icon:hover {
	color: $primary-color;
}

.btn-icon:focus {
	outline: 0;
}

// BUTTONS

.input-file-button {
	display: block;
	background: lighten($color: $light-grey, $amount: 25%);
	color: $grey;
	font-family: $text-font;
	font-size: 1rem;
	border-radius: 7px;
	border: solid 1px $light-grey;
	text-align: center;
	text-decoration: none;
	padding: 1rem;
	transition-duration: 0.5s;
	cursor: pointer;
	font-weight: 600;
}

.input-file-button:hover {
	background: $primary-color;
	border: solid 1px $primary-color;
	color: $white;
}

.rgpd-close-button {
	background: $white;
	color: $primary-color;
	font-family: $text-font;
	font-size: 1rem;
	border-radius: 8px;
	text-align: center;
	text-decoration: none;
	padding: 0.5rem;
	transition-duration: 0.4s;
	cursor: pointer;
	margin-top: 0.5rem;
}

.dark-button {
	background-color: $hover-color;
	color: $white;
	font-family: $text-font;
	@include fluid-type(0.9rem, 0.92rem);
	border-radius: 8px;
	border: none;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	padding: 0.3rem 0.7rem;
	margin: 0.6rem 0.2rem 0.5rem 0;
	transition-duration: 0.4s;
}

.dark-button:hover {
	background-color: $primary-color;
}

.dark-button:focus {
	outline: 0;
}

.light-button {
	background: $light-color;
	color: $primary-color;
	font-family: $text-font;
	@include fluid-type(0.9rem, 0.92rem);
	border-radius: 8px;
	border: solid 1px lighten($color: $light-grey, $amount: 10);
	text-align: center;
	text-decoration: none;
	padding: 0.3rem 0.7rem;
	margin: 0.6rem 0.4rem 0.5rem 0;
	transition-duration: 0.4s;
	cursor: pointer;
}

.light-button:hover {
	background-color: $secondary-color;
	border: solid 1px $secondary-color;
	color: $hover-color;
}

.light-button:focus {
	outline: 0;
}

.light-button:disabled {
	background-color: lighten($color: $light-grey, $amount: 25);
	border: solid 1px lighten($color: $light-grey, $amount: 20);
	color: $light-grey !important;
}

.pink-button {
	background: $secondary-color;
	color: $primary-color;
	font-family: $text-font;
	@include fluid-type(0.9rem, 0.92rem);
	border-radius: 8px;
	text-decoration: none;
	padding: 0.3rem 0.7rem;
	margin: 0.6rem 0.4rem 0.5rem 0;
	transition-duration: 0.4s;
	border: solid 1px lighten($color: $light-grey, $amount: 10);
	cursor: pointer;
}

.pink-button:hover {
	background-color: $primary-color;
	color: $white;
}

.pink-button:focus {
	outline: 0;
}

.pink-button:disabled {
	background-color: lighten($color: $light-grey, $amount: 25);
	color: $primary-color;
}

.red-button {
	background-color: $contrast-color;
	color: $white;
	font-family: $text-font;
	@include fluid-type(0.9rem, 0.95rem);
	font-weight: 600;
	letter-spacing: 0.3px;
	border-radius: 7px;
	border: none;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	padding: 0.3rem 0.7rem;
	margin: 0;
	transition-duration: 0.4s;
}

.red-button:hover {
	background-color: $hover-color;
}

.red-button:focus {
	outline: 0;
}

.unread-button {
	background: $contrast-color;
	font-family: $text-font;
	color: $white;
	@include fluid-type(0.85rem, 0.88rem);
	border-radius: 8px;
	text-decoration: none;
	padding: 0.2rem 0.5rem;
	margin: 0;
	transition-duration: 0.4s;
	border: none;
	cursor: pointer;
	letter-spacing: 0;
	margin-top: -5px;
}

.unread-button:hover {
	background-color: $primary-color;
	color: $white;
}

.unread-button:focus {
	outline: 0;
}

.light-unread-button {
	background: $secondary-color;
	font-family: $text-font;
	color: $primary-color;
	@include fluid-type(0.85rem, 0.88rem);
	border-radius: 8px;
	text-decoration: none;
	padding: 0.2rem 0.5rem;
	margin: 0;
	transition-duration: 0.4s;
	border: none;
	cursor: pointer;
	letter-spacing: 0;
	margin-top: -5px;
}

.light-unread-button:hover {
	background-color: $primary-color;
	color: $white;
}

.light-unread-button:focus {
	outline: 0;
}

.select-card {
	margin: 0.75rem 1.75rem;
}

.small-img {
	padding: 0.5rem;
	max-width: 10rem;
	height: auto;
}

.illustration {
	width: 92%;
	height: auto;
	margin: 1rem auto;
}

.sent-img {
	margin: 2rem 0 0.25rem 0;
}

.close-btn {
	background: $light-color;
	color: $primary-color;
	font-family: $text-font;
	@include fluid-type(0.8rem, 0.85rem);
	text-decoration: none;
	padding: 0 0.7rem 0 0;
	transition-duration: 0.4s;
	cursor: pointer;
	margin: 0;
	border-radius: 7px;
	vertical-align: middle;
}

.close-btn:hover {
	color: $hover-color;
}

.close-btn:focus {
	outline: 0;
}

.close-button {
	background: $light-color;
	color: $primary-color;
	font-family: $text-font;
	@include fluid-type(0.8rem, 0.85rem);
	border-radius: 7px;
	border: solid 1px $light-grey;
	text-align: center;
	text-decoration: none;
	padding: 0.4rem 0.8rem;
	margin-right: 0.5rem;
	transition-duration: 0.4s;
	cursor: pointer;
}

.close-button:hover {
	background-color: $secondary-color;
	border: solid 1px $secondary-color;
}

.close-button:focus {
	outline: 0;
}

.fake-link {
	cursor: pointer;
}

.meta-background {
	background-color: lighten($color: $secondary-color, $amount: 3);
	padding: 0.25rem 0.1rem;
}

// FORM

.input-password {
	position: relative;
}

.view-password {
	position: absolute;
	right: 1rem;
	top: 0.75rem;
	border: none;
	background: none;
	color: $primary-color;
	cursor: pointer;
}

.view-password:focus {
	outline: 0;
}

.table-input {
	input[type="text"] {
		width: 100%;
		padding: 0.2rem;
		margin: 0.2rem 0 0 0;
		display: inline-block;
		border: 1px solid lighten($color: $primary-color, $amount: 50%);
		border-radius: 7px;
		box-sizing: border-box;
		background: $light-color;
		font-family: $text-font;
		@include fluid-type(0.8rem, 0.85rem);
		color: $primary-color;
		-webkit-appearance: textfield;
		letter-spacing: 0.5px;
	}
}

input[type="text"],
input[type="email"],
input[type="password"] {
	width: 100%;
	padding: 0.5rem;
	margin: 0.25rem 0;
	display: inline-block;
	border: 1px solid lighten($color: $primary-color, $amount: 50%);
	border-radius: 5px;
	box-sizing: border-box;
	font-family: $text-font;
	@include fluid-type(0.9rem, 0.95rem);
	color: $primary-color;
	-webkit-appearance: textfield;
	background-color: white;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
	outline: 0;
	border: 1px solid lighten($color: $hover-color, $amount: 10);
	-webkit-appearance: textfield;
}

.custom-select {
	select {
		width: 100%;
		padding: 0.25rem;
		margin: 0.25rem 0;
		display: inline-block;
		border: 1px solid lighten($color: $primary-color, $amount: 50%);
		border-radius: 5px;
		box-sizing: border-box;
		background: $light-color;
		font-family: $text-font;
		@include fluid-type(0.9rem, 0.95rem);
		color: $primary-color;
		-webkit-appearance: listbox;
	}
}

select {
	width: 100%;
	padding: 0.5rem;
	margin: 0.75rem 0 0.25rem 0;
	display: inline-block;
	border: 1px solid lighten($color: $primary-color, $amount: 50%);
	border-radius: 5px;
	box-sizing: border-box;
	background: white;
	font-family: $text-font;
	font-size: 1rem;
	color: $primary-color;
	-webkit-appearance: listbox;
	@include fluid-type(0.9rem, 0.95rem);
}

select:focus {
	outline: 0;
	border: 1px solid lighten($color: $hover-color, $amount: 10);
	-webkit-appearance: listbox;
}

textarea {
	@include fluid-type(0.9rem, 0.95rem);
	padding: 0.5rem;
	margin: 0.25rem 0;
	line-height: 1.7;
	border-radius: 5px;
	border: 1px solid lighten($color: $primary-color, $amount: 50%);
	color: $primary-color;
	font-family: $text-font;
	background-color: white;
	width: 100%;
	-webkit-appearance: textarea;
}

textarea:focus {
	outline: 0;
	border: 1px solid lighten($color: $hover-color, $amount: 15%);
	-webkit-appearance: textarea;
}

input[type="checkbox"] {
	position: relative;
	width: 1rem;
	height: 1rem;
	color: $contrast-color;
	background-color: $white;
	border: 1px solid $grey;
	border-radius: 5px;
	appearance: none;
	outline: 0;
	cursor: pointer;
	vertical-align: middle;

	&::before {
		position: absolute;
		content: "";
		display: block;
		bottom: 2px;
		left: 4px;
		width: 0.5rem;
		height: 1rem;
		border-style: solid;
		border-color: $white;
		border-width: 2px 2px 2px 0;
		transform: rotate(45deg);
		opacity: 0;
	}
	&:checked {
		color: $white;
		border: solid 2px $hover-color;
		background: $hover-color;
		&::before {
			opacity: 1;
		}
	}
}

// Home (login page)

.home-logo {
	width: 15rem;
	height: auto;
	margin: 2rem auto;
	display: block;
}

#login-form {
	margin: 0 auto;
	width: 100%;
	max-width: 21rem;
	padding-top: 2.2rem;
}

#password-form {
	margin: 0 auto;
	width: 100%;
	max-width: 28rem;
	padding-top: 1rem;
}

#login-module {
	background-color: $secondary-color;
	padding: 2rem 0;
	text-align: center;
	margin: 1rem 0;

	h1 {
		padding: 0;
		margin: 0;
	}

	.slogan {
		font-family: $text-font;
		color: $hover-color;
		font-size: 1.2rem;
		letter-spacing: 2px;
		padding: 0;
		font-weight: 400;
		display: block;
		text-align: center;
	}
}

#newpassword-module {
	background-color: $secondary-color;
	padding: 1rem 0;
	height: 8rem;
	text-align: center;
}

// #welcome-module {
// 	background-color: $secondary-color;
// 	left: 50%;

// 	.slogan {
// 		color: $primary-color;
// 		text-align: center;
// 		padding: 2rem 0;
// 	}
// }

.home-button {
	background-color: $primary-color;
	color: $white;
	font-family: $text-font;
	@include fluid-type(1rem, 1.1rem);
	border-radius: 8px;
	border: none;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	padding: 0.5rem 2rem;
	margin: 0;
	transition-duration: 0.4s;
}

.home-button:hover {
	background-color: $hover-color;
}

.home-button:focus {
	outline: 0;
}

// SPINNER

.loader {
	color: $light-grey;
	font-size: 20px;
	margin: 100px auto;
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	position: relative;
	text-indent: -9999em;
	-webkit-animation: load4 1.3s infinite linear;
	animation: load4 1.3s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}
@-webkit-keyframes load4 {
	0%,
	100% {
		box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
			-2em -2em 0 0;
	}
	12.5% {
		box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
			-2em -2em 0 -1em;
	}
	25% {
		box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
			-2em -2em 0 -1em;
	}
	37.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em,
			-2em -2em 0 -1em;
	}
	50% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em,
			-2em -2em 0 -1em;
	}
	62.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0,
			-2em -2em 0 -1em;
	}
	75% {
		box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em,
			-2em -2em 0 0;
	}
	87.5% {
		box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0,
			-2em -2em 0 0.2em;
	}
}
@keyframes load4 {
	0%,
	100% {
		box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
			-2em -2em 0 0;
	}
	12.5% {
		box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
			-2em -2em 0 -1em;
	}
	25% {
		box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
			-2em -2em 0 -1em;
	}
	37.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em,
			-2em -2em 0 -1em;
	}
	50% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em,
			-2em -2em 0 -1em;
	}
	62.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0,
			-2em -2em 0 -1em;
	}
	75% {
		box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em,
			-2em -2em 0 0;
	}
	87.5% {
		box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0,
			-2em -2em 0 0.2em;
	}
}

// RESPONSIVE

@media screen and (max-width: 768px) {
	.home-logo {
		width: 11rem;
	}

	#login-form {
		margin: 0 auto;
		max-width: 21rem;
		padding-top: 2.5rem;
	}

	#login-module {
		background-color: $secondary-color;
		padding: 1.2rem 2rem;
		width: 100vw;
		position: relative;
		margin-left: -50vw;
		height: 130px;
		left: 50%;

		h1 {
			font-family: $title-font;
			@include fluid-type(1.4rem, 1.5rem);
			letter-spacing: 0rem;
			padding: 0;
			margin: 0.5rem 0;
		}

		.slogan {
			@include fluid-type(1rem, 1.1rem);
			letter-spacing: 1px;
		}
	}

	.content-card {
		margin: 0 1rem;
	}

	.content-container {
		padding: 0.5rem;
	}
}
